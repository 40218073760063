import { apiClient } from '@app/lib'
import { type ApiResponse, type SugarMe } from '@app/types'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

export const useMe = (initialData?: SugarMe | null) => {
  const session = useSession()

  return useQuery({
    enabled: session.status === 'authenticated',
    queryKey: ['me'],
    queryFn: async () => {
      const { data } = await apiClient.get<ApiResponse<SugarMe>>(`/api/me`)

      return data.data
    },
    initialData,
    staleTime: 300000 // 5 minutes
  })
}
