import { useMe } from '@app/lib'
import { type SugarExpenditureSchoolYear, type SugarMe, type SugarOption, type SugarOptionName } from '@app/types'
import { find } from 'lodash'
import { createContext, type FC, type ReactNode, useCallback, useContext, useMemo } from 'react'

export const SugarContext = createContext<
  | (Partial<SugarMe> & {
      getOptionByValue: (option: SugarOptionName, value: string) => SugarOption | undefined
      getOptionLabelByValue: (option: SugarOptionName, value: string) => string | undefined
      getExpenditureReportingSchoolYearByValue: (schoolYearId: string) => SugarExpenditureSchoolYear | undefined
      getExpenditureReportingSchoolYearQuarterByValue: (
        schoolYearId: string,
        quarterId: string
      ) => SugarOption | undefined
      isProviderRequired: boolean
    })
  | null
>(null)

export const useSugarContext = () => useContext(SugarContext)

export interface SugarProviderProps {
  children: ReactNode
  initialData?: SugarMe
}

export const SugarProvider: FC<SugarProviderProps> = ({ children, initialData }) => {
  const { data } = useMe(initialData)
  const isProviderRequired = useMemo(
    () => !!data?.options?.businessProviders?.length,
    [data?.options?.businessProviders?.length]
  )
  const getOptionByValue = useCallback(
    (option: SugarOptionName, value: string) => find(data?.options[option], { value }),
    [data]
  )
  const getOptionLabelByValue = useCallback(
    (option: SugarOptionName, value: string) => getOptionByValue(option, value)?.label,
    [getOptionByValue]
  )
  const getExpenditureReportingSchoolYearByValue = useCallback(
    (schoolYearId: string) => find(data?.options.expenditureReportingSchoolYears, { value: schoolYearId }),
    [data?.options.expenditureReportingSchoolYears]
  )
  const getExpenditureReportingSchoolYearQuarterByValue = useCallback(
    (schoolYearId: string, quarterId: string) =>
      find(getExpenditureReportingSchoolYearByValue(schoolYearId)?.quarters, { value: quarterId }),
    [getExpenditureReportingSchoolYearByValue]
  )

  return (
    <SugarContext.Provider
      value={{
        ...data,
        getExpenditureReportingSchoolYearByValue,
        getExpenditureReportingSchoolYearQuarterByValue,
        getOptionByValue,
        getOptionLabelByValue,
        isProviderRequired
      }}
    >
      {children}
    </SugarContext.Provider>
  )
}
